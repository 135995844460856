
#title-screen {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;

    background: url('/images/pine.png');
}

#title-menu {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;

    padding: 2rem;
    margin: 0;
}

#title-menu img {
    height: 40vh;
}

#title {
    font-size: 5em;
    margin: 5vh 0 0 0;
}

#title-subheading {
    font-size: 2em;
    margin: 0;
}

#play-button {
    text-align: center;
    font-size: 2em;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 2vh;
    padding-bottom: 2vh;

    margin-top: 5vh;
}

